import React from 'react';
import * as styles from './rcMitarbeiter.module.scss';
import { GatsbyImage } from "gatsby-plugin-image"

import XingSVG from "../../icons/fontawesome/brands/xing.svg";
import LinkedInSVG from "../../icons/fontawesome/brands/linkedin.svg";
import PhoneSVG from "../../icons/fontawesome/light/phone-alt.svg";
import AtSVG from "../../icons/fontawesome/light/at.svg";

import QuotationSVG from "../../icons/fontawesome/light/quote-right.svg";

const RcMitarbeiter = ( { mitarbeiter } ) => {

  return <div className={"row mb-5 my-xl-6 align-items-center " + styles.mitarbeiter}>

    <div className={"col-12 col-lg-4 mb-2 mb-lg-0 " + (mitarbeiter.imageleftright === "right" ? " order-lg-1 " : "")  }>
      <GatsbyImage image={mitarbeiter.image.localFile.childImageSharp.gatsbyImageData} />
    </div>

    <div className="col-12 col-lg-8">

      <div className="d-flex align-items-center justify-content-between">
        <h3 className={"mr-3"}>{mitarbeiter.name}</h3>

        <div className={styles.social + " py-2 d-flex"}>
          { mitarbeiter.mail && <a href={"mailto:" + mitarbeiter.mail}> <AtSVG className={"svgFill"} /> </a> }
          { mitarbeiter.phone && <a href={"tel:" + mitarbeiter.phone}> <PhoneSVG className={"svgFill"} />  </a> }
          { mitarbeiter.xing && <a href={mitarbeiter.xing} target={"_blank"}> <XingSVG className={"svgFill"} /> </a> }
          { mitarbeiter.linkedin && <a href={mitarbeiter.linkedin} target={"_blank"}> <LinkedInSVG className={"svgFill"} /> </a> }
        </div>
      </div>

      <div dangerouslySetInnerHTML={
        {
          __html: mitarbeiter.description
        }
      } />

      { mitarbeiter.quotation &&

      <div className={styles.quotation}>
        <QuotationSVG className={"svgFill mr-2"} />
        { mitarbeiter.quotation }
      </div>
      }

    </div>




  </div>

};

export default RcMitarbeiter;
