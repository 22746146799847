import React, { useEffect, useState } from "react"
import * as styles from "./betriebsmarkt.module.scss"
import "./about-wordpress.scss";

import { graphql } from "gatsby"
import Layout from "../page-components/layout"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"
import RcMitarbeiter from "../pages-parts/team/rcMitarbeiter"

const AboutPage = ( { data } ) => {

  const [ state, setState ] = useState('team');

  const teamNode = data.allWpPage.nodes.find(n => n.slug === "team");
  const referenzenNode = data.allWpPage.nodes.find(n => n.slug === "referenzen");
  const partnerNode = data.allWpPage.nodes.find(n => n.slug === "partner");

  const [ page, setPage ] = useState(teamNode);

  useEffect(
    () => {
      const getParams = new URLSearchParams(window.location.search);

      if (getParams.has("state")) {
        const state = getParams.get("state");

        if (state === "partner") {
          setState("partner");
          setPage(partnerNode)
        } else if (state === "team") {
          setState('team');
          setPage(teamNode);
        }
      }
    },
    []
  );

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Über uns</h1>

      <div dangerouslySetInnerHTML={
        {
          __html: data.wp.texteFRSektionen.acf.introteam
        }
      } />

    </RcDefaultPageTeaser>


    <div className="container bg-white h-100">

      <div className="row">
        <div className="d-flex w-100 justify-content-between">
          <p className={styles.tabButton + " " + (state === 'team' ? styles.active : "") }
             onClick={ (e) => { setState('team'); setPage(teamNode); } }>
            Team
          </p>

          <p className={styles.tabButton + " " + (state === 'partner' ? styles.active : "") }
             onClick={ (e) => { setState('partner'); setPage(partnerNode); } }>
            Partner
          </p>

          <p className={styles.tabButton + " " + (state === 'referenzen' ? styles.active : "") }
             onClick={ (e) => { setState('referenzen'); setPage(referenzenNode); } }>
            Referenzen
          </p>

        </div>
      </div>

      <div className="row">
        <div className="col-12">


          <div className="container h-100 bg-white">

            <div className="row">
              <div className="col-12">

                <div className="py-3 py-lg-5">
                  <div className={"contentWrapper " + page.acf?.paragraphmargin + " content"}>
                    <div dangerouslySetInnerHTML={ { __html: page.content }} />

                    { page.acf.mitarbeiter && page.acf.mitarbeiter.length > 0 &&
                      page.acf.mitarbeiter.map(
                        (m) => {
                          return <RcMitarbeiter mitarbeiter={m} />
                        }
                      )
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>
      </div>


    </div>


  </Layout>

};

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
  
    wp {
      texteFRSektionen {
          acf {
            introteam
          }
      }
    }
  
    allWpPage(filter: {slug: {in: ["team", "partner", "referenzen"]}}) {
      nodes {
        acf {
          art
          description
          mitarbeiter {
            description
            imageleftright
            linkedin
            mail
            phone
            quotation
            name
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          paragraphmargin
          subcaption
        }
        slug
        title
        content
      }
    }
  }`
